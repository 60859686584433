import 'url-polyfill';
import React, { Component } from 'react';
import Ajax from '../components/Ajax';
import withTracker from '../components/withTracker';
import { SetTitle } from '../components/Common';
import { saveAs } from "file-saver";
import { FilePdfOutlined } from '@ant-design/icons';
import { Layout } from '../components/base';

const { Header, Footer, Content } = Layout;
import { ReactComponent as CompanyLogo } from '../img/mr_spedag_logo.svg';
import './DownloadPublicFile.scss';

const dlUrl = `${process.env.PUBLIC_URL}/api/v1/File/DownloadPublicFile`;

class DownloadPublicFile extends Component {
    //constructor(props) {
    //    super(props);
    //    this.state = {
    //        dataUrl: ""
    //    };
    //}

    componentDidMount() {
        SetTitle('Download Public File');
        
        let urlParams = new URLSearchParams(window.location.search);
        let filename = urlParams.get("filename");
       
        //this.downloadPublicFile(filename);
        //if (this.state.dataUrl) {
        //    URL.revokeObjectURL(this.state.dataUrl);
        //}
        //this.getPreviewFile(filename);
    }

    downloadPublicFile = async function (filename) {
        let url = new URL(dlUrl, window.location.href);
        url.searchParams.append('filename', filename);

        let res = await Ajax.get(`${dlUrl}/${filename}`, 'blob');

        if (res.data.size > 0) {
            var blob = new Blob([res.data], { type: "application/octet-stream" });
            blob.name = `${filename}.pdf`;
            saveAs(blob);
        }
    }

    //getPreviewFile =  (filename) => {
    //    const url = new URL(dlUrl, window.location.href);
    //    url.searchParams.append('filename', filename);

    //    Ajax.get(`${dlUrl}/${filename}`, 'blob').then(res => {
    //        const pdfUrl = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
    //        this.setState({ dataUrl: pdfUrl });
    //    });
    //}

    render() {
        const now = new Date();

        return (
            <Layout className="root">
                <Header className="header">
                    <CompanyLogo className="companyLogo" />
                </Header>
                <Layout className="main">
                    <Content className="mainContent">
                        <div>
                            <h1>Privacy Policy</h1>
                            <div>
                                <FilePdfOutlined onClick={() => this.downloadPublicFile('PrivacyPolicyDoc')} />
                                <a onClick={() => this.downloadPublicFile('PrivacyPolicyDoc')}>Privacy Policy - M+R Forwarding (China) Ltd.</a> 
                            </div>
                            <h1>Statement and Authorization on Personal Information Export</h1>
                            <div>
                                <FilePdfOutlined onClick={() => this.downloadPublicFile('PersonalInformationExport')} />
                                <a onClick={() => this.downloadPublicFile('PersonalInformationExport')}>Statement and Authorization on Personal Information Export</a> 
                            </div>
                        </div>
                    </Content>
                    <Footer className="footer">
                        <a href="https://www.mrspedag.com/">M+R SPEDAG GROUP</a> &copy; {now.getFullYear()}
                    </Footer>
                </Layout>
            </Layout>
        );
    }
}

export default withTracker(DownloadPublicFile);
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';

import history from './components/history';
import App from './App';
import Login from './screens/Login';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import Registration from './screens/Registration/Registration';
import RegistrationWithSIS from './screens/Registration/RegistrationWithSIS';
import RegistrationWithoutSIS from './screens/Registration/RegistrationWithoutSIS';
import DownloadPublicFile from './screens/DownloadPublicFile';
import registerServiceWorker from './registerServiceWorker';
import PrivacyPolicyAcceptance from './components/PrivacyPolicyAcceptance';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import TagManager from 'react-gtm-module'


if (process.env.REACT_APP_GTM_CONTAINER_ID) {
    TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
        userProject: 'BMS'
    });
}

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <Router history={history}>
        <Switch>
            <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
            <Route path={`${process.env.PUBLIC_URL}/forgotpassword`} component={ForgotPassword} />
            <Route path={`${process.env.PUBLIC_URL}/resetpassword`} component={ResetPassword} />
            <Route path={`${process.env.PUBLIC_URL}/Registration`} component={Registration} />
            <Route path={`${process.env.PUBLIC_URL}/RegistrationWithSIS`} component={RegistrationWithSIS} />
            <Route path={`${process.env.PUBLIC_URL}/RegistrationWithoutSIS`} component={RegistrationWithoutSIS} />
            <Route path={`${process.env.PUBLIC_URL}/china-privacy-policy`} component={DownloadPublicFile} />
            <Route component={App} />
        </Switch>
        <PrivacyPolicyAcceptance/>
    </Router>,
  rootElement);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();